<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="row mb-4">
                    <div class="col-12 mb-3">
                        <router-link to="/admin/courses/create" class="btn btn-current float-right mt-3"><i class="fas fa-plus"></i> Create New</router-link>
                        <h1 class="mt-4 font-weight-bolder"><i class="fas fa-home mr-3"></i>Courses</h1>
                    </div>
                    <div  class="col-12">
                        <div class="row">
                            <div class="col-10">
                                <input type="text" class="form-control" @change="changeFilter()" placeholder="Search..." v-model="search">
                            </div>
                            <div class="col-2">
                                <select v-model="category" @change="changeFilter()" class="form-control">
                                    <option value="">Select All</option>
                                    <option value="SHINE">Shine</option>
                                    <option value="GLORY">Glory</option>
                                    <option value="TRIUMPH">Triumph</option>
                                    <option value="EXCLUSIVE">Exclusive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="!loadCourse">
                    <div class="col-12" v-if="courses && courses.data.length">
                        <div class="row">
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4 p-auto" v-for="(item, index) in courses.data" :key="index">
                                <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                                    <div class="card-image w-100 mb-3 overflow-hidden rounded-lg">
                                        <router-link :to="'/admin/courses/' + item.slug + '/home'" class="position-relative d-block"><img :src="item.banner ? item.banner : 'https://via.placeholder.com/400x300.png'" alt="image" class="w-100"></router-link>
                                    </div>
                                    <div class="card-body">
                                        <span class="font-xsssss fw-700 pl-2 pr-2 lh-32 text-uppercase rounded-lg ls-2 alert-warning d-inline-block text-warning mr-1">{{item.category}} - {{item.level}}</span>
                                        <span class="font-xss fw-700 pl-3 pr-3 ls-2 lh-32 d-inline-block text-success float-right"><span class="font-xsssss">Rp.</span> {{item.price | numFormat('0,0')}}</span>
                                        <h4 class="fw-700 font-xss mt-3 lh-28 mt-0"><router-link :to="`/admin/courses/${item.slug}/home`" class="text-dark text-grey-900">{{item.name}}</router-link></h4>
                                        <h6 class="font-xssss text-grey-500 fw-600 ml-0 mt-2">{{item.description}}</h6>
                                        <hr>
                                        <div class="row d-flex justify-content-between">
                                            <router-link title="Announcements" :to="`/admin/courses/${item.slug}/announcements`" class="col"><i class="text-muted fas fa-bullhorn"></i></router-link>
                                            <router-link title="Assignment" :to="`/admin/courses/${item.slug}/assignments`" class="col"><i class="text-muted fas fa-clipboard-list"></i></router-link>
                                            <router-link title="Attendance" :to="`/admin/courses/${item.slug}/attendance`" class="col"><i class="text-muted fas fa-check"></i></router-link>
                                            <router-link title="File" :to="`/admin/courses/${item.slug}/files`" class="col"><i class="text-muted fas fa-folder"></i></router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mb-4">
                                <ul class="pagination pagination-center justify-content-center d-flex pt-5">
                                    <li v-for="(link, index) in courses.links" :key="index" class="page-item m-1">
                                        <button v-if="link.label === '&laquo; Previous'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="prevCourse()"><i class="ti-angle-left"></i></button>
                                        <button v-else-if="link.label === 'Next &raquo;'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="nextCourse()"><i class="ti-angle-right"></i></button>
                                        <button v-else v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" @click="changeCourse(link.label)">{{link.label}}</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center py-3" v-else>
                        <img :src="'/images/empty.png'" alt="" width="300">
                        <h1 class="text-muted">Data don't exist</h1>
                    </div>
                </div>
                <div class="row justify-content-center d-flex" v-else>
                    <div class="col-xl-12 col-lg-12 text-center py-3">
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "Courses",
        data(){
            return {
                courses: [],
                loadCourse: true,
                page: 1,
                search: '',
                category: ''
            }
        },
        mounted() {
            this.getCourses(this.page)
        },
        methods: {
            getMedia(path){
                return process.env.VUE_APP_URL_CLOUD + path
            },
            changeFilter() {
                this.page = 1;
                this.getCourses(this.page)
            },
            async getCourses(page){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/course?page=${parseInt(page)}&search=${this.search}&category=${this.category}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.courses = res.data
                    this.loadCourse = false
                }).catch((err) => console.error(err))
            },
            changeCourse(page) {
                this.page = parseInt(page)
                this.getCourses(this.page)
            },
            prevCourse() {
                if (this.page >= 1) {
                    this.page -= 1
                    this.getCourses(this.page)
                }
            },
            nextCourse() {
                if (this.courses.last_page > this.page) {
                    this.page += 1
                    this.getCourses(this.page)
                }
            },
        },
        computed: {
            fullpath: function() {
                return this.courses.data.map(function(item) {
                    return process.env.VUE_APP_URL_CLOUD + item.banner;
                });
            }
        }
    }
</script>
